


































































































































































































































































































import CreditCardForm from '@/components/payments/CreditCardForm.vue';
import GetSonyPaymentToken from '@/components/payments/GetSonyPaymentToken.vue';
import { SubscriptionRepository } from '@/repositories/SubscriptionRepository';
import SavedCardForm from '@/components/payments/SavedCardForm.vue';
import { Component, Vue } from 'vue-property-decorator';
import { SubscriptionInfo } from '@/models/Subscription';
import { InputCard } from '@/models/InputCard';

import {
  getSubscribedPackageIds,
  getSubscriptionStatusByPackageId,
  calcNextPayDt
} from '@/common/subscriptionUtils';
import { ResPostSubscription, ResSubscriptionInfo } from '@/gen';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { SubscriptionPackageCmsRepository } from '@/repositories/SubscriptionPackageCmsRepository';
import RegistrationComplete from '@/components/subscription/RegistrationComplete.vue';
import RegisterConfirmDialog from '@/components/subscription/RegisterConfirmDialog.vue';
import Classification from '@/common/classification';
import {
  INFO_SUBSCRIPTION_CLAIMKEY,
  SUBSCRIPTION_CONTRACT_END_HOUR,
  SUBSCRIPTION_PACKAGE_NOT_UPDATE
} from '@/common/constants';
import {
  isPackageBeforeOneMonthFromClosing,
  isNextUpdateForFree
} from '@/common/subscriptionUtils';

@Component({
  components: {
    CreditCardForm,
    SavedCardForm,
    GetSonyPaymentToken,
    RegistrationComplete,
    RegisterConfirmDialog
  }
})
export default class P0220 extends Vue {
  // データフェッチ時にエラーが発生した場合の表示メッセージ
  fetchErrorMsg = '';
  // サブスク登録時にエラーが発生した場合の表示メッセージ
  postErrorMsg = '';

  /**
   * USER INPUT
   */
  // 決済ラジオボタンの選択値
  radioSelectedCreditCard = '';
  // 会員規約同意フラグ
  isAgreed = false;
  // 入力されたクレジットカード情報
  inputCard = {} as InputCard;
  // カードトークン
  token = '';

  /**
   * 画面制御項目
   */
  // 登録ボタン複数回押下防止フラグ
  buttonLoading = false;
  // サブスク登録完了フラグ
  isRegistrationComplete = false;
  // 登録確認モーダル表示フラグ
  isRegisterConfirmDialogOpening = false;
  // 次回更新料金無料判定フラグ
  isNextUpdateForFree = false;
  // パッケージ登録操作終了日時
  registrationCloseDt: Number | undefined = undefined;
  // パッケージ終了日時（microCMSの設定値より AM6:00 を計算する）
  packageEndDt: Date | undefined = undefined;

  /**
   * APIレスポンスレシーバー
   */
  // microCMSレスポンス
  subscriptionPackageInfo = {} as SubscriptionPackageItem;
  // サブスク登録情報レスポンス
  subscriptionInfo = {} as SubscriptionInfo;
  // サブスク登録APIレスポンス
  resPostSubscription = {} as ResPostSubscription;

  $refs!: {
    CreditCardForm: CreditCardForm;
    GetSonyPaymentToken: GetSonyPaymentToken;
  };

  // サブスクパッケージID
  get subscriptionPackageID() {
    return this.$route.params.id;
  }

  // 保存済みカード選択状態では、クレジットカード入力欄を非活性に設定
  get inputCardDisableFlag() {
    return (
      this.radioSelectedCreditCard === this.$cls.CREDIT_CARD_CLS.SAVED_CARD.CD
    );
  }

  get subscriptionRepo() {
    return new SubscriptionRepository();
  }
  get subscriptionPackageCmsRepo() {
    return new SubscriptionPackageCmsRepository();
  }
  get nextPayDt() {
    return calcNextPayDt(this.subscriptionPackageInfo.payInterval);
  }
  get isFirstTimeRegistration() {
    return (
      getSubscriptionStatusByPackageId(
        this.$auth.user[INFO_SUBSCRIPTION_CLAIMKEY],
        this.subscriptionPackageID
      ) ===
      Classification.SUBSCRIPTION_STATUS_CLS.NEW_REGISTER_ADDRESS_NOT_EXISTS.CD
    );
  }
  get isRegistered() {
    return getSubscribedPackageIds(
      this.$auth.user[INFO_SUBSCRIPTION_CLAIMKEY]
    ).includes(this.subscriptionPackageID);
  }

  async created() {
    // 既にサブスク登録中であればサブスク状態表示画面へリダイレクト
    if (this.isRegistered) {
      this.$router.push({
        name: 'manage-subscription',
        hash: `#${this.subscriptionPackageID}`
      });
    }
    // microCMS・Odinから情報取得
    try {
      await this.getSubscriptionPackageInfo();
      const now = new Date();
      // パッケージ登録操作終了日時もしくはパッケージ終了日を超えている場合、サブスク状態表示画面へリダイレクト
      if (
        (this.subscriptionPackageInfo.registrationCloseDt &&
          new Date(this.subscriptionPackageInfo.registrationCloseDt) <= now) ||
        (!!this.packageEndDt && this.packageEndDt <= now)
      ) {
        this.$router.push({
          name: 'manage-subscription',
          hash: `#${this.subscriptionPackageID}`
        });
      }
    } catch {
      this.fetchErrorMsg = this.$msg.get('2000078');
      return;
    }
    await this.getSubscriptionInfo();

    // 保存済みカードが存在する場合は、デフォルトで保存済みカードを選択
    this.radioSelectedCreditCard = this.subscriptionInfo.maskedCardNumber
      ? this.$cls.CREDIT_CARD_CLS.SAVED_CARD.CD
      : this.$cls.CREDIT_CARD_CLS.ANOTHER_CARD.CD;
    // 保存済みカード選択状態では、クレジットカード入力欄を非活性に設定
  }

  /**
   * クレジットカード選択ラジオボタン選択時に実行する関数
   * @param event DOM イベントオブジェクト
   */
  selectCreditCardRadio(event: any) {
    // 保存済を選択した場合、カード情報入力部分を非活性化・入力値の削除・バリデーションエラーの削除を行う
    this.radioSelectedCreditCard = event.target.value;
    if (
      this.radioSelectedCreditCard === this.$cls.CREDIT_CARD_CLS.SAVED_CARD.CD
    ) {
      this.inputCard.number = '';
      this.inputCard.expYear = '';
      this.inputCard.expMonth = '';
      this.inputCard.securityCode = '';
      this.$refs.CreditCardForm.$validator.reset();
    }
  }

  /**
   * 申込ボタン押下時に実行する関数
   */
  beforeRegister() {
    this.postErrorMsg = '';
    // 保存済みカードで支払う場合
    if (
      this.radioSelectedCreditCard === this.$cls.CREDIT_CARD_CLS.SAVED_CARD.CD
    ) {
      this.isRegisterConfirmDialogOpening = true;
    }
    // 新規カードで支払う場合
    else {
      this.$refs.CreditCardForm.$validator.validateAll().then(valid => {
        if (valid) {
          this.getPaymentToken();
        } else {
          this.buttonLoading = false;
        }
      });
    }
  }

  /**
   * サブスクパッケージ情報の取得 from microCMS
   */
  async getSubscriptionPackageInfo() {
    await this.subscriptionPackageCmsRepo
      .select(this.subscriptionPackageID)
      .then(res => {
        if (!res) {
          throw undefined;
        }
        this.subscriptionPackageInfo = res;
        // 登録可能期間の算出のため、(サブスク登録操作終了日時 -1 秒)を計算する
        if (this.subscriptionPackageInfo.registrationCloseDt) {
          this.registrationCloseDt =
            new Date(
              this.subscriptionPackageInfo.registrationCloseDt
            ).getTime() - 1000;
        }
        // パッケージ終了日時の算出のため、「パッケージ終了日」の設定値に AM6:00 をセットする
        if (this.subscriptionPackageInfo.packageEndDt) {
          const pkgEndDt = new Date(this.subscriptionPackageInfo.packageEndDt);
          this.packageEndDt = this.convertToAm6(pkgEndDt);
        }
      })
      .catch(() => {
        this.fetchErrorMsg = this.$msg.get('2000078');
      });
  }

  /**
   * サブスク登録情報の取得 from odin
   */
  async getSubscriptionInfo() {
    await this.subscriptionRepo
      .getSubscriptionInfo()
      .then(res => {
        this.subscriptionInfo = res;
      })
      .catch((errCode: number) => {
        switch (errCode) {
          case 42206: // サブスク契約が1件も存在しない場合、エラーメッセージはセットせず SubscriptionInfo を初期化する
            this.subscriptionInfo = SubscriptionInfo.valueOf({
              card_info: {
                masked_card_number: '',
                expiry_ym: ''
              },
              subscription_contracts: []
            } as ResSubscriptionInfo);
            break;
          default:
            this.fetchErrorMsg = this.$msg.get('2000078');
        }
      });
  }

  /**
   * サブスク登録
   */
  register() {
    if (this.buttonLoading) {
      return;
    }
    this.buttonLoading = true;
    this.subscriptionRepo
      .postSubscription(this.token, this.subscriptionPackageID)
      .then(async res => {
        // idトークンの更新
        await this.$auth.getTokenSilently({ ignoreCache: true });
        // 登録完了画面を表示し、画面TOPへスクロール
        this.resPostSubscription = res;
        this.isRegistrationComplete = true;
        window.scrollTo(0, 0);

        // 登録完了をトラッキングするためにデータレイヤーにプッシュ
        this.$dataLayer.push({
          event: 'form_completion',
          subscription_package_id: this.subscriptionPackageID
        });
      })
      // eslint-disable-next-line complexity
      .catch((errCode: number) => {
        switch (errCode) {
          case 40004:
          case 40010:
          case 42200:
          case 42204:
          case 42205:
            this.postErrorMsg = this.$msg.get('2000074', {
              errorCode: errCode
            });
            break;
          case 42201:
          case 42202:
          case 42203:
            this.postErrorMsg = this.$msg.get('2000070', {
              errorCode: errCode
            });
            break;
          case 50000:
          case 50001:
          case 50005:
            this.postErrorMsg = this.$msg.get('2000071', {
              errorCode: errCode
            });
            break;
          case 50007:
            this.postErrorMsg = this.$msg.get('2000079');
            break;
          case 50009:
            this.postErrorMsg = this.$msg.get('2000119', {
              errorCode: errCode
            });
            break;
          case 40014:
            this.$router.push({
              name: 'manage-subscription',
              hash: `#${this.subscriptionPackageID}`
            });
            break;
          default:
            this.postErrorMsg = this.$msg.get('2000073');
        }
      })
      .finally(() => {
        this.isRegisterConfirmDialogOpening = false;
        this.buttonLoading = false;
      });
  }

  /**
   * 決済トークンを取得し、Vuexストアに保存
   */
  getPaymentToken() {
    this.$refs.GetSonyPaymentToken.getPaymentToken(
      this.inputCard.number,
      this.inputCard.expYear,
      this.inputCard.expMonth,
      this.inputCard.securityCode
    );
  }

  handleToken(token: string, err: any) {
    if (err) {
      this.postErrorMsg = this.$msg.get('2000073');
      this.buttonLoading = false;
    } else {
      this.token = token;
      this.isRegisterConfirmDialogOpening = true;
    }
  }

  /**
   * 以下、画面表示項目の出し分け判定に利用する
   */

  // パッケージ登録操作終了日時より1ヶ月以内の場合 true を返却する。終了日時が設定されていない場合はfalseを返却する。
  isPackageBeforeOneMonthFromClosing() {
    return this.subscriptionPackageInfo.registrationCloseDt
      ? isPackageBeforeOneMonthFromClosing(
          this.subscriptionPackageInfo.registrationCloseDt
        )
      : false;
  }

  // 次回更新がパッケージ終了間近かつ請求金額が無料（OER負担）の場合 true を返却する。終了日時が設定されていない場合はfalseを返却する。
  isNextPaymentForFree() {
    if (!this.subscriptionPackageInfo.packageEndDt) {
      return false;
    }
    this.isNextUpdateForFree = isNextUpdateForFree(
      this.subscriptionPackageInfo.packageEndDt,
      this.subscriptionPackageInfo.payInterval,
      this.subscriptionPackageInfo.packageEndingPattern[0]
    );
    return this.isNextUpdateForFree;
  }

  // 次回更新がある場合 true を返却する
  // 更新・請求パタンが「更新なし」の場合は、パッケージ終了日まで更新間隔×2の期間が確保できるかで判断する
  isNextTermUpdatePackage() {
    if (!this.packageEndDt) {
      return true;
    }
    const payInterval = this.subscriptionPackageInfo.payInterval;
    // 「更新あり」の場合、次回更新時の契約期間がパッケージ終了日まで1日以上確保できるか
    if (
      this.subscriptionPackageInfo.packageEndingPattern[0] !==
      SUBSCRIPTION_PACKAGE_NOT_UPDATE
    ) {
      const nextPayDate = calcNextPayDt(payInterval).toDate();
      return this.convertToAm6(nextPayDate) < this.packageEndDt;
    }
    // 「更新なし」の場合、次回更新時の契約期間がパッケージ終了日まで更新間隔分確保できるか
    const nextPayDate = calcNextPayDt(
      payInterval,
      calcNextPayDt(payInterval).toString()
    ).toDate();
    return this.convertToAm6(nextPayDate) <= this.packageEndDt;
  }

  // 引数で受け取った日付をAM6:00に揃える
  convertToAm6(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      SUBSCRIPTION_CONTRACT_END_HOUR,
      0,
      0
    );
  }
}
