import { SubscriptionPackageResponse } from '@/models/cms/subscriptionPackage';

export class SubscriptionPackageItem {
  static valueOf(s: SubscriptionPackageResponse) {
    return new SubscriptionPackageItem(
      s.id,
      s.createdAt,
      s.publishedAt,
      s.updatedAt,
      s.revisedAt,
      s.packageName,
      s.payInterval,
      s.payAmount,
      s.initialPayAmount,
      s.termsOfService,
      s.unsubscribeMessage,
      s.actOnSCT.id,
      s.packageEndingPattern,
      s.registrationCloseDt,
      s.packageEndDt
    );
  }

  constructor(
    readonly id: string, // サブスクパッケージID
    readonly createdAt: string, // microCMSシステム項目
    readonly publishedAt: string, // microCMSシステム項目
    readonly updatedAt: string, // microCMSシステム項目
    readonly revisedAt: string, // microCMSシステム項目
    readonly packageName: string, // パッケージ名
    readonly payInterval: number, // 更新間隔
    readonly payAmount: number, // 更新料金
    readonly initialPayAmount: number, // 初回登録時料金
    readonly termsOfService: string, // 会員規約
    readonly unsubscribeMessage: string, // 解約時表示文言
    readonly articleID: string, // 特定商取引法に基づく表記の記事ID
    readonly packageEndingPattern: Array<string>, // パッケージ終了に伴う更新間隔に満たない契約の更新・請求パタン
    readonly registrationCloseDt?: string, // パッケージ登録操作終了日時
    readonly packageEndDt?: string // パッケージ終了日
  ) {}
}
