import Classification from '@/common/classification';
import dayjs from 'dayjs';
import {
  SUBSCRIPTION_PACKAGE_UPDATE_AND_FOR_FREE,
  SUBSCRIPTION_CONTRACT_END_HOUR
} from './constants';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';

/**
 * getSubscribedPackageIds は、登録中のサブスクリプションパッケージの ID の配列を返します。
 */
export function getSubscribedPackageIds(subscriptionStatus: any): string[] {
  if (isSubscriptionStatus(subscriptionStatus)) {
    const status = Classification.SUBSCRIPTION_STATUS_CLS.REGISTERING.CD;
    return subscriptionStatus
      .filter(s => s.status === status)
      .map(s => s.subscription_package_id);
  }

  return [];
}

/**
 * getSubscriptionStatusByPackageId は、指定したサブスクパッケージIDのステータスを返します。
 */
export function getSubscriptionStatusByPackageId(
  subscriptionStatus: any,
  packageId: string
): string {
  if (isSubscriptionStatus(subscriptionStatus)) {
    const s = subscriptionStatus.find(
      s => s.subscription_package_id === packageId
    );
    return s
      ? s.status
      : Classification.SUBSCRIPTION_STATUS_CLS.NEW_REGISTER_ADDRESS_NOT_EXISTS
          .CD;
  } else {
    return Classification.SUBSCRIPTION_STATUS_CLS
      .NEW_REGISTER_ADDRESS_NOT_EXISTS.CD;
  }
}

type SubscriptionStatus = {
  subscription_package_id: string;
  status: string;
}[];

function isSubscriptionStatus(
  maybeSubscriptionStatus: any
): maybeSubscriptionStatus is SubscriptionStatus {
  if (!Array.isArray(maybeSubscriptionStatus)) {
    return false;
  }

  for (const item of maybeSubscriptionStatus) {
    if (
      !(
        item !== null &&
        typeof item === 'object' &&
        typeof item.subscription_package_id === 'string' &&
        typeof item.status === 'string'
      )
    ) {
      return false;
    }
  }

  return true;
}

/**
 * 次回のサブスク更新日時を返却する
 * @param payInterval 更新間隔
 * @param lastPayDt 直近の支払日（指定しなかった場合は本日）
 * @returns 次回支払日
 */
export function calcNextPayDt(
  payInterval: number,
  lastPayDt?: string | null
): dayjs.Dayjs {
  // 更新間隔ヶ月後の日付を取得
  const dayAfterPayInterval = dayjs(lastPayDt)
    .add(payInterval, 'month')
    .hour(3)
    .minute(0);
  // ちょうどNヶ月後の日付が存在しない（lastPayDt と dayAfterPayInterval の日にちが異なる）場合、
  // 次回支払日は (N+1)ヶ月後 の 1日 となる
  if (dayjs(lastPayDt).date() !== dayjs(dayAfterPayInterval).date()) {
    return dayjs(dayAfterPayInterval)
      .add(1, 'month')
      .date(1);
  }
  return dayAfterPayInterval;
}

/**
 * サービス終了直前まで更新間隔分の更新のみを行い続けた場合の契約終了日を返却する。
 * パッケージ終了に伴う更新間隔に満たない契約を発生させないパッケージにおいて、更新を行い続けた場合の契約終了日と一致する。
 * @param payInterval 更新間隔
 * @param contractEndDt 現在の契約終了日
 * @param packageEndDt パッケージの終了日
 * @returns サービス終了直前まで更新間隔分の更新のみを行い続けた場合の契約終了日
 */
export function calcFinalFullTermContractEndDt(
  payInterval: number,
  contractEndDt: string,
  packageEndDt: string
): string {
  if (!packageEndDt) {
    return contractEndDt;
  }
  let lastEndDt = contractEndDt;
  let nextEndDt = calcNextPayDt(payInterval, lastEndDt).hour(
    SUBSCRIPTION_CONTRACT_END_HOUR
  );
  while (!nextEndDt.isAfter(packageEndDt)) {
    lastEndDt = nextEndDt.toString();
    nextEndDt = calcNextPayDt(payInterval, lastEndDt).hour(
      SUBSCRIPTION_CONTRACT_END_HOUR
    );
  }
  return lastEndDt;
}

/**
 * パッケージ登録操作終了日時まで1ヶ月以内の場合 true を返す
 * @param registrationCloseDt パッケージ登録操作終了日時
 * @returns boolean
 */
export function isPackageBeforeOneMonthFromClosing(
  registrationCloseDt: string
) {
  return (
    new Date(new Date().setMonth(new Date().getMonth() + 1)) >=
    new Date(registrationCloseDt)
  );
}

/**
 * 次回更新時に作成される契約の期間が更新間隔に満たない、かつ更新・請求パタンが「更新あり/OER負担」の場合 true を返す
 * @param registrationCloseDt パッケージ終了日
 * @param payInterval 更新間隔
 * @param packageEndingPattern 更新・請求パタン
 * @param contractEndDt 登録中の契約終了日(subscription_contracts.end_dt) （指定しない場合は本日から計算する）
 * @returns boolean
 */
export function isNextUpdateForFree(
  packageEndDt: string,
  payInterval: number,
  packageEndingPattern: string,
  contractEndDt?: string | null
): boolean {
  // microCMSの設定値「パッケージ終了日」をAM6:00として扱う
  const pkgEndDt = new Date(
    new Date(packageEndDt).getFullYear(),
    new Date(packageEndDt).getMonth(),
    new Date(packageEndDt).getDate(),
    6,
    0,
    0
  );
  const endDt = contractEndDt ?? calcNextPayDt(payInterval).toString();
  const nextEndDt = calcNextPayDt(payInterval, endDt).toDate();
  if (packageEndingPattern === SUBSCRIPTION_PACKAGE_UPDATE_AND_FOR_FREE) {
    return (
      new Date(endDt) < new Date(pkgEndDt) && nextEndDt > new Date(pkgEndDt)
    );
  }
  return false;
}

/**
 * パッケージがサービス終了する日時を返す。
 * @param packageList パッケージ情報のリスト
 * @param packageId 取得対象のパッケージID
 * @returns サービス終了日時
 */
export function getSubscriptionPackageEndDt(
  packageList: Array<SubscriptionPackageItem>,
  packageId: string
): string {
  const subscriptionPackage = packageList.find(p => p.id === packageId);
  if (!subscriptionPackage) {
    return '';
  }
  if (!subscriptionPackage.packageEndDt) {
    return '';
  }
  return dayjs(subscriptionPackage.packageEndDt)
    .hour(SUBSCRIPTION_CONTRACT_END_HOUR)
    .toString();
}

/**
 * パッケージの名称を返す。
 * @param packageList パッケージ情報のリスト
 * @param packageId 取得対象のパッケージID
 * @returns パッケージ名称
 */
export function getSubscriptionPackageName(
  packageList: Array<SubscriptionPackageItem>,
  packageId: string
): string {
  const subscriptionPackage = packageList.find(p => p.id === packageId);
  return subscriptionPackage?.packageName || '';
}

/**
 * パッケージの申込みが終了する日時を返す。
 * @param packageList パッケージ情報のリスト
 * @param packageId 取得対象のパッケージID
 * @returns パッケージの申込みが終了する日時
 */
export function getRegistrationCloseDt(
  packageList: Array<SubscriptionPackageItem>,
  packageId: string
): string {
  const subscriptionPackage = packageList.find(p => p.id === packageId);
  return subscriptionPackage?.registrationCloseDt
    ? dayjs(subscriptionPackage.registrationCloseDt)
        .subtract(1, 'second')
        .toString()
    : '';
}
