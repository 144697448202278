import { fetchListContents, selectContent } from '@/common/cms';
import { SubscriptionPackageResponse } from '@/models/cms/subscriptionPackage';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { ISubscriptionPackageCmsRepository } from '@/repositories/interface/ISubscriptionPackageCmsRepository';

export class SubscriptionPackageCmsRepository
  implements ISubscriptionPackageCmsRepository {
  private readonly PATH = 'subscription-packages';
  private readonly LIMIT = 100;

  /**
   * サブスクパッケージ全量取得
   */
  public async get() {
    try {
      const { data } = await fetchListContents<SubscriptionPackageResponse>(
        this.PATH,
        {
          limit: this.LIMIT
        }
      );
      return data.contents.length
        ? data.contents.map(c => SubscriptionPackageItem.valueOf(c))
        : null;
    } catch (_) {
      return null;
    }
  }

  /**
   * サブスクパッケージ単体取得
   * @param id サブスクパッケージID
   */
  public async select(id: string) {
    try {
      const res = await selectContent<SubscriptionPackageResponse>(
        this.PATH,
        id
      );
      return SubscriptionPackageItem.valueOf(res.data);
    } catch (_) {
      return null;
    }
  }
}
